<template>
<h1>Videos</h1>
</template>

<script>
export default {
  name: "Videos"
}
</script>

<style scoped>

</style>
